import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss']
})
export class ImpressumPage {
	static url = 'impressum';
	environment = environment;

	constructor(private repository: RepositoryService, private router: Router) {}

	static navigate(router: Router) {
		router.navigateByUrl(ImpressumPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
